(function() {
    const images = document.getElementsByClassName("ind-imageScroll");

    let globalIndex = 0,
        last = { x: 0, y: 0 };

    const activate = (image, x, y) => {
        image.style.left = `${x}px`;
        image.style.top = `${y}px`;
        image.dataset.status = "active";
        last = { x, y };
    }

    const distanceFromLast = (x, y) => {
        return Math.hypot(x - last.x, y - last.y);
    }

    const imageBDropSection = document.getElementById('imageBDrop');

    imageBDropSection.addEventListener('mousemove', e => {
        if (distanceFromLast(e.clientX, e.clientY) > 100) {
            const lead = images[globalIndex % images.length];
            const tail = images[(globalIndex - 5) % images.length];

            activate(lead, e.clientX, e.clientY);

            if (tail) tail.dataset.status = "inactive";

            globalIndex++;
        }
    });
})();